<template>
  <div class="container">
    <b-row class="no-gutters height-self-center">
      <b-col sm="12" class="text-center align-self-center">
        <div class="mm-error position-relative">
          <img
            src="@/assets/images/error/404.png"
            class="img-fluid mm-error-img"
            alt=""
          />
          <h2 class="mb-0 mt-4">{{ $t("errorMsgs.pageNotFoundTitle") }}</h2>
          <p>{{ $t("errorMsgs.pageNotFoundText") }}</p>
          <router-link
            class="btn btn-primary d-inline-flex align-items-center mt-3"
            :to="{ name: 'order-overview' }"
            ><i class="fas fa-home mr-2"></i
            >{{ $t("generalMsgs.backToHome") }}</router-link
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Error404",
};
</script>